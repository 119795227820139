var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calculator" },
    [
      _c(
        "zg-slider-with-input",
        {
          attrs: {
            "input-width": _vm.inputWidth[_vm.MARKET],
            label: _vm.content.amount,
            max: _vm.LOAN_AMOUNT.MAX,
            min: _vm.LOAN_AMOUNT.MIN,
            "read-only": _vm.readOnly,
            step: _vm.LOAN_AMOUNT.STEP,
            value: _vm.loanAmount,
            name: "loan-amount",
          },
          on: {
            change: function ($event) {
              _vm.setLoanAmount($event)
              _vm.sliderChanged("loanAmountSlider")
            },
            input: function ($event) {
              return _vm.setLoanAmount($event)
            },
          },
        },
        [
          _c("form-input", {
            attrs: { field: "loanAmountSimple", group: "global" },
            on: {
              blur: function ($event) {
                return _vm.setLoanAmount()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "zg-slider-with-input",
        {
          attrs: {
            "input-width": _vm.inputWidth[_vm.MARKET],
            label: _vm.content.repayment,
            max: _vm.DURATION.MAX,
            min: _vm.DURATION.MIN,
            "read-only": _vm.readOnly,
            step: _vm.DURATION.STEP,
            value: _vm.repaymentYears,
            name: "repayment-years-amount",
          },
          on: {
            change: function ($event) {
              _vm.setRepaymentYears($event)
              _vm.sliderChanged("repaymentYearsSlider")
            },
            input: function ($event) {
              return _vm.setRepaymentYears($event)
            },
          },
        },
        [
          _c("form-input", {
            attrs: { field: "repaymentYearsSimple", group: "global" },
            on: {
              input: function ($event) {
                return _vm.setRepaymentYears()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMonthlyCost,
              expression: "showMonthlyCost",
            },
          ],
          staticClass: "monthly-cost",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.content.monthlyCost) +
              "\n\n    " +
              _vm._s(_vm.monthlyCost) +
              "*\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }